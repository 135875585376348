import React from "react"
import { Text } from "gatsby-interface"
import { MdInfo } from "react-icons/md"
import { changePlanRequest as text } from "@modules/locales/default.js"

export default function Lede({ nextPlanName }) {
  const lede = text[nextPlanName]
    ? text[nextPlanName].lede
    : text[`default`].lede
  const note = (text[nextPlanName] && text[nextPlanName].note) || null

  return (
    <React.Fragment>
      <Text>{lede}</Text>
      {note && (
        <Text
          css={theme => ({
            background: theme.colors.yellow[5],
            border: `1px solid ${theme.colors.yellow[10]}`,
            color: theme.colors.yellow[90],
            margin: `${theme.space[8]} 0 ${theme.space[7]}`,
            padding: `${theme.space[5]} ${theme.space[7]}`,
          })}
        >
          <MdInfo
            css={{
              verticalAlign: `middle`,
              transform: `translate(0, -10%)`,
            }}
          />
          {` `}
          {note}
        </Text>
      )}
    </React.Fragment>
  )
}
