import React from "react"
import {
  TextAreaConnectedField,
  RadioButtonConnectedField,
} from "gatsby-interface"

export default function RequestDetail({ downgradeOptions, isDowngrade }) {
  return isDowngrade ? (
    <React.Fragment>
      <RadioButtonConnectedField
        label="Reason for changing plan"
        name="downgradeReason"
        required
        options={downgradeOptions.map(reason => ({
          label: reason,
          value: reason,
        }))}
      />
      <TextAreaConnectedField
        label="Please provide more detail"
        name="reasonDetail"
        rows={4}
        required
      />
    </React.Fragment>
  ) : (
    <TextAreaConnectedField
      label="Reason for changing plan"
      name="reasonDetail"
      rows={4}
      required={true}
    />
  )
}
