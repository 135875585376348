import * as React from "react"
import {
  useChangePlanMutation,
  useCancelPlanMutation,
} from "@modules/billing/queries.generated"
import { useMutation } from "react-apollo"
import { REQUEST_PLAN_CHANGE } from "@modules/billing/queries"
import usePlanChangeState from "@modules/billing/shared/hooks/usePlanChangeState"
import { getPathToOrgSettings } from "@modules/organization/shared/utils"
import { navigate } from "gatsby"
import {
  PlanTier,
  HandlePlanChangeInput,
  SimplifiedPlanTier,
} from "@modules/billing/components/RequestForm/types"
import useTracker from "@modules/analytics/hooks/useTracker"
import { SegmentEventType } from "@modules/analytics"
import { MachinePricingTier } from "@modules/graphql/types"
import { useFlags } from "@modules/featureFlags"

export const isTierDowngrade = (
  currentTier?: PlanTier | SimplifiedPlanTier,
  nextTier?: PlanTier | SimplifiedPlanTier
): boolean => {
  let downgrade = false

  if (!nextTier) return downgrade
  switch (currentTier) {
    case `ENTERPRISE`:
      if (nextTier !== `ENTERPRISE`) {
        downgrade = true
      }
      break
    case `PROFESSIONAL`:
      if (
        nextTier !== `PROFESSIONAL` &&
        nextTier !== `ENTERPRISE` &&
        nextTier !== `AGENCY`
      ) {
        downgrade = true
      }
      break
    case `AGENCY`:
      if (nextTier !== `ENTERPRISE`) {
        downgrade = true
      }
      break
    case `PERFORMANCE`:
      if (
        nextTier !== `PROFESSIONAL` &&
        nextTier !== `PERFORMANCE` &&
        nextTier !== `ENTERPRISE` &&
        nextTier !== `AGENCY`
      ) {
        downgrade = true
      }
      break
    case `STANDARD`:
      if (nextTier === `FREE`) {
        downgrade = true
      }
      break
    default:
      downgrade = false
  }

  return downgrade
}

export const isDowngrade = ({
  hostingTier,
  nextHostingTier,
  buildsTier,
  nextBuildsTier,
  tier,
  nextTier,
}: {
  hostingTier?: PlanTier
  nextHostingTier?: PlanTier
  buildsTier?: PlanTier
  nextBuildsTier?: PlanTier
  tier?: SimplifiedPlanTier
  nextTier: SimplifiedPlanTier
}): boolean => {
  const hostingDowngrade = isTierDowngrade(hostingTier, nextHostingTier)
  const buildsDowngrade = isTierDowngrade(buildsTier, nextBuildsTier)
  const isCompositeDowngrade =
    isTierDowngrade(tier, nextTier) ||
    isTierDowngrade(hostingTier, nextTier) ||
    isTierDowngrade(buildsTier, nextTier)
  if (isCompositeDowngrade || hostingDowngrade || buildsDowngrade) {
    return true
  }

  return false
}

export const isEnterpriseUpgrade = ({
  hostingTier,
  nextHostingTier,
  buildsTier,
  nextBuildsTier,
  tier,
  nextTier,
}: {
  hostingTier?: PlanTier
  nextHostingTier?: PlanTier
  buildsTier?: PlanTier
  nextBuildsTier?: PlanTier
  tier?: SimplifiedPlanTier
  nextTier?: SimplifiedPlanTier
}): boolean => {
  const enterpriseHosting =
    hostingTier !== "ENTERPRISE" && nextHostingTier === "ENTERPRISE"
  const enterpriseBuilds =
    buildsTier !== "ENTERPRISE" && nextBuildsTier === "ENTERPRISE"
  const compositeEnterpriseUpgrade =
    tier !== "ENTERPRISE" && nextTier === "ENTERPRISE"

  if (compositeEnterpriseUpgrade || enterpriseHosting || enterpriseBuilds) {
    return true
  }
  return false
}

export const isAgencyUpgrade = ({
  tier,
  nextTier,
  flag = false,
}: {
  tier?: SimplifiedPlanTier
  nextTier?: SimplifiedPlanTier
  flag?: boolean
}) => {
  return flag && tier !== nextTier && nextTier === MachinePricingTier.Agency
}

export function useHandleSubmit<T>(
  {
    organizationId,
    currentPlan,
    hostingTier,
    buildsTier,
    tier,
    billingInterval,
    nextPlanInfo,
    formValues,
  }: HandlePlanChangeInput,
  setError: React.Dispatch<React.SetStateAction<T | null>>
) {
  const { flags } = useFlags()
  const { trackSegment } = useTracker()
  const [changePlan] = useChangePlanMutation()
  const [cancelPlan] = useCancelPlanMutation()
  const [sendRequest] = useMutation(REQUEST_PLAN_CHANGE)
  const { storePlanChangeRequest } = usePlanChangeState(organizationId)

  const {
    planName: nextPlanName,
    hostingTier: nextHostingTier,
    buildsTier: nextBuildsTier,
    tier: nextTier,
    planId: nextPlanId,
    billingInterval: nextBillingInterval,
  } = nextPlanInfo

  const handler = async () => {
    try {
      if (nextPlanId === `free`) {
        await cancelPlan({ variables: { organizationId } })
        trackSegment({
          type: SegmentEventType.Track,
          event: `Cancel Plan Form Submitted`,
          properties: {
            organizationId,
            plan: currentPlan,
            newPlan: `Free`,
            downgradeReason: formValues?.downgradeReason || ``,
            moreDetails: formValues?.reason || ``,
          },
        })
      } else if (
        !isEnterpriseUpgrade({
          buildsTier,
          hostingTier,
          nextBuildsTier,
          nextHostingTier,
          tier,
          nextTier,
        }) &&
        !isAgencyUpgrade({
          tier,
          nextTier,
          flag: flags.pricingFall2022,
        })
      ) {
        await changePlan({
          variables: {
            organizationId,
            nextPlanId,
          },
        })
        trackSegment({
          type: SegmentEventType.Track,
          event: `Changed Plan Form Submitted`,
          properties: {
            organizationId,
            plan: currentPlan,
            newPlan: nextPlanInfo?.planName,
            downgradeReason: formValues?.downgradeReason || ``, // applicable when the ...
            moreDetails: formValues?.reason || ``, // change is a downgrade
          },
        })
      }

      await sendRequest({
        variables: {
          request: {
            ...formValues,
            plan: buildsTier,
            currentPlan,
            buildsTier,
            hostingTier,
            billingInterval,
            nextTier,
            nextBuildsTier,
            nextHostingTier,
            nextBillingInterval,
            organizationId,
          },
        },
      })

      storePlanChangeRequest(nextPlanName)
      navigate(getPathToOrgSettings(organizationId))
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError(err as any)
    }
  }

  return handler
}
