import React from "react"
import { Text } from "gatsby-interface"

export default function TrialDowngradeWarning() {
  return (
    <Text
      css={theme => ({
        background: theme.colors.yellow[5],
        border: `1px solid ${theme.colors.yellow[10]}`,
        color: theme.colors.yellow[90],
        margin: `${theme.space[8]} 0 ${theme.space[7]}`,
        padding: `${theme.space[5]} ${theme.space[7]}`,
      })}
    >
      {`It looks like you're currently on our free trial! Your account will automatically be changed to the free tier at the end of your trial.`}
    </Text>
  )
}
