import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

import Amex from "@modules/payment/assets/Amex"
import Discover from "@modules/payment/assets/Discover"
import Mastercard from "@modules/payment/assets/Mastercard"
import Visa from "@modules/payment/assets/Visa"
import Chip from "./Chip"
import { Text } from "@modules/ui/typography"
import { billing as text } from "@modules/locales/default.js"

const CreditCardRoot = styled(`div`)`
  background: ${p => p.theme.colors.grey[10]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: ${p => p.theme.space[5]} ${p => p.theme.space[5]}
    ${p => p.theme.space[8]} ${p => p.theme.space[8]};
  margin-top: ${p => p.theme.space[7]};
  width: 100%;

  > svg {
    margin-top: ${p => p.theme.space[5]};
    width: 48px;
  }

  ${p => p.theme.mediaQueries.tablet} {
    width: 45%;
  }
`

const CardDetails = styled(Text)`
  font-size: ${p => p.theme.fontSizes[2]};
  margin: 0;

  :last-child {
    font-size: ${p => p.theme.fontSizes[1]};
  }
`

const CardNumber = styled(CardDetails)`
  letter-spacing: ${p => p.theme.space[2]};
`

const Row = styled(`div`)`
  display: flex;
  width: 100%;

  :first-of-type {
    justify-content: flex-end;
  }

  :last-child {
    align-items: center;
    justify-content: space-between;
    margin-top: ${p => p.theme.space[5]};
  }

  svg {
    width: 38px;
    height: 24px;
  }
`

const ExpirationDetails = styled(`div`)`
  display: flex;
  flex-direction: column;
`

const ExpirationLabel = styled(CardDetails)`
  color: ${p => p.theme.colors.grey[40]};
  text-transform: uppercase;
  font-size: 0.5rem;
  margin: 0;
`

function CreditCard({
  creditCard: { brand, lastFour, expMonth, expYear, name },
}) {
  return (
    <CreditCardRoot className="fs-block">
      <Row>
        {brand === `VISA` && <Visa />}
        {brand === `MASTERCARD` && <Mastercard />}
        {brand === `AMEX` && <Amex />}
        {brand === `DISCOVER` && <Discover />}
      </Row>
      <Chip />
      <CardNumber>•••• •••• •••• {lastFour}</CardNumber>
      <Row>
        <CardDetails>{name}</CardDetails>
        <ExpirationDetails>
          <ExpirationLabel>{text.monthYear}</ExpirationLabel>
          <CardDetails>
            {expMonth} / {expYear}
          </CardDetails>
        </ExpirationDetails>
      </Row>
    </CreditCardRoot>
  )
}

CreditCard.propTypes = {
  billing: PropTypes.object,
}

export default CreditCard
