import React from "react"
import PropTypes from "prop-types"

import {
  StyledModal,
  StyledModalHeader,
  StyledModalBody,
  StyledModalActions,
  Button,
  ModalCard,
  Modal,
} from "gatsby-interface"

export default function ConfirmPlanChange({
  isOpen,
  setIsOpen,
  setSubmitting,
  handleSubmit,
  nextPlanName,
  nextBillingInterval,
}) {
  const isCancellation = nextPlanName === "Free"
  const variant = isCancellation ? "WARNING" : "ACTION"
  return (
    <React.Fragment>
      <Modal aria-label="Plan change details to confirm" isOpen={isOpen}>
        <ModalCard>
          <StyledModal variant={variant}>
            <StyledModalHeader
              onCloseButtonClick={() => {
                setIsOpen(false)
                setSubmitting(false)
              }}
            >
              Please confirm your plan change
            </StyledModalHeader>
            <StyledModalBody>
              <div css={{ marginBottom: "20px" }}>
                Are you sure you want to change to the following plan?
              </div>
              <div>
                Plan: <strong>{nextPlanName}</strong>
              </div>
              {!isCancellation ? (
                <div>
                  Billing Interval:{" "}
                  <strong>
                    {nextBillingInterval === `MONTHLY` ? `MONTHLY` : `YEARLY`}
                  </strong>
                </div>
              ) : (
                <div css={{ marginTop: "20px" }}>
                  This will take effect at the end of your billing period.
                </div>
              )}
              <StyledModalActions>
                <Button
                  onClick={() => {
                    setIsOpen(false)
                    setSubmitting(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={async () => {
                    setIsOpen(false)
                    await handleSubmit()
                    setSubmitting(false)
                  }}
                >
                  Confirm
                </Button>
              </StyledModalActions>
            </StyledModalBody>
          </StyledModal>
        </ModalCard>
      </Modal>
    </React.Fragment>
  )
}

ConfirmPlanChange.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
}
